// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import { N600, N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { READING_AIDS_DIALOG_WIDTH } from '../constants';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
export const ErrorBoundaryFallbackText = styled.div<{
	isReadingAids?: boolean;
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
}>((props) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	gap: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	padding: props.isReadingAids
		? `${token('space.150', '12px')} ${token('space.300', '20px')} ${token('space.150', '12px')}`
		: `${token('space.250', '20px')} ${token('space.300', '24px')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	...(props.isReadingAids
		? {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				font: token('font.body.large'),
				color: token('color.text.accent.gray.bolder', N800),
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				maxWidth: `calc(${READING_AIDS_DIALOG_WIDTH} - 40px)`,
				maxHeight: '200px',
				overflowY: 'auto',
			}
		: {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				font: token('font.body'),
				color: token('color.text.accent.gray', N600),
				maxHeight: '417px',
				overflowY: 'auto',
				width: '100%',
			}),
}));
