import React from 'react';

import Icon from '@atlaskit/icon';
import type { CustomGlyphProps, IconProps } from '@atlaskit/icon/types';

const ThumbUpIconGlyph = (props: CustomGlyphProps) => (
	<svg
		width="24"
		height="25"
		viewBox="0 0 24 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		data-testid={props['data-testid']}
		aria-label={props['aria-label']}
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		className={props.className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.25343 11.2414C8.91051 10.9653 8.47456 10.8 8 10.8H7C5.89543 10.8 5 11.6954 5 12.8V16.8C5 17.9046 5.89543 18.8 7 18.8H8C8.67485 18.8 9.27163 18.4657 9.63383 17.9538C10.2849 18.3768 10.7807 18.662 11.3054 18.8277C12.0107 19.0504 12.7452 19.0503 13.8834 19.05L14 19.05C15.0903 19.05 15.9813 18.8458 16.6904 18.4322C17.4092 18.0129 17.8817 17.4128 18.1854 16.7296C18.7507 15.4577 18.7503 13.8227 18.75 12.3982L18.75 12.3C18.75 11.5224 18.51 10.8745 17.9772 10.4585C17.4757 10.0669 16.8553 9.99471 16.337 10.021C15.9545 10.0404 15.5603 10.1174 15.1903 10.2181C15.3396 9.59744 15.3911 9.06134 15.3574 8.60069C15.2973 7.77936 14.9562 7.15144 14.3859 6.80924C13.8454 6.48496 13.2272 6.49699 12.7564 6.6704C12.3346 6.82578 11.75 7.23911 11.75 7.95222C11.75 9.50422 10.5995 10.64 9.25343 11.2414ZM9.98525 12.5558C11.5349 11.8264 13.1841 10.3475 13.2481 8.08854C13.256 8.08517 13.2649 8.08163 13.2749 8.07796C13.3978 8.03268 13.5296 8.04474 13.6141 8.09547C13.6688 8.12827 13.8277 8.25033 13.8614 8.71016C13.8966 9.19143 13.7825 9.9764 13.3037 11.1735C13.1901 11.4574 13.2602 11.7817 13.4809 11.9934C13.7015 12.205 14.0286 12.2615 14.3074 12.1362C14.8088 11.9109 15.6934 11.5555 16.413 11.5191C16.7697 11.501 16.9618 11.5688 17.0541 11.6408C17.115 11.6884 17.25 11.8276 17.25 12.3C17.25 13.8528 17.2363 15.1716 16.8147 16.1204C16.6183 16.5622 16.3408 16.8996 15.9346 17.1365C15.5187 17.3792 14.9098 17.55 14 17.55C12.7053 17.55 12.2155 17.5421 11.7571 17.3973C11.3369 17.2646 10.9234 17.0108 10 16.3982V12.8C10 12.7173 9.99499 12.6359 9.98525 12.5558ZM13.1916 8.1224C13.1904 8.12189 13.1946 8.11685 13.2077 8.10879C13.1994 8.11888 13.1928 8.12291 13.1916 8.1224ZM7 12.3H8C8.27614 12.3 8.5 12.5238 8.5 12.8V16.8C8.5 17.0761 8.27614 17.3 8 17.3H7C6.72386 17.3 6.5 17.0761 6.5 16.8V12.8C6.5 12.5238 6.72386 12.3 7 12.3Z"
			fill="currentColor"
		/>
	</svg>
);

/**
 * __ThumbUpIcon__
 */
const ThumbUpIcon = ({ label, primaryColor, secondaryColor, size, testId }: IconProps) => (
	<Icon
		label={label}
		primaryColor={primaryColor}
		secondaryColor={secondaryColor}
		size={size}
		testId={testId}
		glyph={ThumbUpIconGlyph}
	/>
);

export default ThumbUpIcon;
